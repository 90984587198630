
.cart_main{
  background: url('../assets/login_bg.png');
  background-color: #F4E9DC;
  height: fit-content;
  margin-top: 0;
  padding-top: 5px;
 
}
:root{
color: #723207;
}
.cart_main .prod-head{
display: flex;
justify-content: space-around;
font-size: 20px;
font-weight: bolder;
width: 100%;
}
.cart1 {
  width: 80%;
  background-color: #F4E9DC;
  margin: auto;
  padding: 20px;
  border: 1px solid #651c1c;
  
  
}

.cart1 .cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #651c1c;
  padding: 10px 0;
  justify-content: space-around;
 

}
.cart1 .prod-det{
  display: flex;
  flex-direction: column;
 width: 10%;
  

}

.cart1  .cart-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart1  .cart-item-details {

 width: 80%;
 margin-left: 5%;
 display: flex;
  justify-content: space-around;

}

.cart1  .cart-item-details h3,
.cart-item-details p {
  margin: 5px 0;
}

.cart1  .cart-item-details input {
  width: 50px;
  margin-left: 10px;
}

.cart1  .cart-item-details button {
  padding: 5px 10px;
  background-color: #723207;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3ms all  ease-in-out;
}

.cart1  .cart-item-details button:hover {
  background-color: #DCC0A9;
  
}


.cart1 .proceed {
  display: flex;
  width: 100%;
  justify-content: space-around;

}
.cart1 .proceed h2 {
  margin-top: 20px;
  float: left;
}

.cart1 .proceed button{
  font-size: 20px;
  background-color: #651c1c;
  outline: none;
  border: none;
  padding: 10px;
  margin-top: 20px;
  color: white;
  
  cursor: pointer;

  float: right;
}
.cart1 .proceed button:hover{
  background-color:#DCC0A9;
}

.cart1 .cart-item-details input[type='number']{
  border: none;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0 0 2px 0;
  
}
@media(max-width:768px) {
  .cart_main .prod-head{
display: none;
}
.cart1 .prod-det{
flex-direction: row;
width: 100%;
}
.cart1 .cart-item-details{
flex-direction: column;
width: 50%;
}
.cart1 .cart-item{
justify-content: space-between;
}
.cart1 .category_cart{
display: none;
}
.cart1 .proceed h2 {
font-size: 16px;
}
.cart1 .proceed button{
font-size: 16px;
margin-top: 16px;
}
.cart1 {
border: none;
}
  
}
@media (max-width: 420px) {
  .cart1 .prod-det {
    flex-direction: column;
  }
  .cart1 .cart-item-details button{
    font-size: 10px;
  }
}
