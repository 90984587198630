/* Default styling for desktop */
.body1 {
  width: 100%;
}

.body1 .main {
  background: url('../assets/Home.png');
  background-size: cover;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}

.body1 .header {
  width: 50%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 150px;
}

.body1 .container {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 20px;
  width: 100%;
  justify-content: space-around;
}

.body1 .footer-section {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  /* width: fit-content; */
  /* padding-right: 50px; */
  min-width: 160px;
}

.body1 .footer-section h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
}

.body1 .footer-section ul {
  list-style: none;
  padding: 0;
}

.body1 .footer-section ul li {
  margin: 5px 0;
  cursor: pointer;
}

.body1 .footer-section ul li:hover {
  color: rgb(146, 107, 107);
}

.body1 .payment-methods {
  width: fit-content;
  display: flex;
  /* justify-content: center; */
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.body1 .payment-methods img {
  width: 20%;
  height: 50px; /* Set fixed height */
    width: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image doesn't overflow the container */
    background-color: transparent; /* Remove background color */
    object-fit: contain; /* Ensure image fits within the bounds */
cursor: pointer;

}

.body1 .connect {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}

.body1 .connect h2, .body1 .connect_p {
  width: 100%;
  margin-top: 30px;
}

.body1 .connect .input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border: 1px solid wheat;
 
  border-radius: 20px;
}

.body1 .connect .input-box .btn {
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  height: 100%;
  background: brown;
  padding: 12px;
  border-radius: 20px;
}
.body1 .connect .input-box .btn:hover{
  background-color: rgb(114, 19, 19);
}

.body1 input[type="email"] {
  width: 63%;
  padding: 10px 15px;
  font-size: 16px;
  border: 0;
  outline: none;
  background: transparent;
}

.body1 input[type="email"]:focus {
  border-color: #007BFF;
  color: white;
}

.body1 input[type="email"]::placeholder {
  color: #aaa;
  font-size: 14px;
}

.body1 .social-media {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body1 .social-media .icons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.body1 .icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  background-color: transparent;
  border-radius: 50%;
  font-size: 2rem;
  color: white;
  transition: .5s ease;
}

.body1 .icons a:hover {
  background: white;
  color: #323946;
  box-shadow: 0 0 1rem white;
}

.policy_footer a {
  text-decoration: none;
  color: aliceblue;
}
.body1  hr{
  width: 75%;
  display: none;
}
.alsoavail{

  width: 100%;

}
.alsoavail h2{
  font-size: 16px;
}
.alsoavail .company_sub1{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  flex-wrap: wrap;
}
.alsoavail .company_logo1{
  width: 100px;
  height: fit-content;
 
}
.alsoavail .company_sub1 .company_logo1 img {
  content: attr(title);
    height: 25px; /* Set fixed height */
    width: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image doesn't overflow the container */
    background-color: transparent; /* Remove background color */
    object-fit: contain; /* Ensure image fits within the bounds */
cursor: pointer;
}
/* Responsive design for screens <= 1024px */
@media (max-width: 1080px) {
  .body1 .main {
    
      align-items: center;
      padding: 20px;
  }

  .body1 .header {
      width: 100%;
      margin-left: 0;
  }

  .body1 .connect {
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 50px;
  }

  .body1 .payment-methods {
      justify-content: center;
      margin: 20px 0;
      width: 100%;
  }

  .body1 .payment-methods img {
      width: 15%; /* Adjusted size for smaller screens */
  }
  
}

/* Responsive design for screens <= 768px */
@media (max-width: 768px) {
  .body1 .main {
      padding: 20px;
      gap: 0;
  }

  .body1 .header {
      width: 100%;
  }

  .body1 .connect {
      justify-content: center;
      margin: 20px;
  }

  .body1 .payment-methods {
      
      align-items: center;
  }

  .body1 .payment-methods img {
      width: 24%; /* Adjusted size for mobile screens */
  }
}

