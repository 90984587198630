.order-completion-main {
    padding: 20px;
}

.order-completion-container {
    max-width: 600px;
    margin: auto;
    text-align: center;
}

.order-completion-container button {
    margin-top: 20px;
    padding: 10px;
    cursor: pointer;
}
