@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
}

.Wpp {
  width: 80%;
  padding: 20px 0;
  margin: 0 auto;
}

.top {
  margin-top: 100px;
  text-align: center;
}

.contain {
  margin-top: 0px;
}

.Wpp .slick-dots {
  bottom: 0px;
}

/* Customize dot styles */
.Wpp .slick-dots li button:before {
  color: #665353;
  font-size: 8px;
}

/* Customize active dot style */
.Wpp .slick-dots li.slick-active button:before {
  color: #723207;
  font-size: 15px;
}

.Wpp .slide {
  transition: transform 300ms, opacity 300ms;
  opacity: 0.7;
  padding: 0 10px;
  overflow: hidden;
  transform: scale(0.8);
}

.Wpp .activeSlide {
  transform: scale(1);
  opacity: 1;
}

.Wpp .slick-track {
  display: flex;
  align-items: center;
}

/* @media (max-width: 768px) {
  .top {
    margin-top: 0;
  }

  .Wpp {
    width: 100%;
    padding: 0;
  }

  .Wpp .slick-track {
    height: fit-content;
  }

  .contain {
    margin-top: 0px;
  }
} */

@media (max-width: 1080px) {
  .Wpp {
    width: 90%;
  }

  .Wpp .slick-track {
    height: fit-content;
  }

  .contain {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .top {
    margin-top: 0;
  }

  .Wpp {
    width: 100%;
    padding: 0;
  }

  .Wpp .slick-track {
    height: fit-content;
  }

  .contain {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .top {
    margin-top: 0;
  }

  .Wpp {
    width: 100%;
    padding: 0;
  }

  .Wpp .slick-track {
    height: fit-content;
  }

  .contain {
    margin-top: 20px;
  }
  .Wpp .slide {
    transition: transform 300ms, opacity 300ms;
    opacity: 1;
    padding: 0 10px;
    overflow: hidden;
    transform: scale(1);
  }
  
  .Wpp .activeSlide {
    transform: scale(1);
    opacity: 1;
  }
}
