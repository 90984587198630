/* ConfirmationModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .modal-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-actions button:first-child {
    background-color: #d9534f;
    color: white;
  }
  
  .modal-actions button:last-child {
    background-color: #5bc0de;
    color: white;
  }
  .modal-actions button:first-child:hover {
    background-color: #a4221d;
    color: white;
  }
  
  .modal-actions button:last-child:hover {
    background-color: #2b85a0;
    color: white;
  }