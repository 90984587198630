/* OrderDetailsStyles.css */

.order-details {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-height: 400px; /* Limit height to enable scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Box shadow for depth effect */
    font-family: Arial, sans-serif; /* Example: Set font family */
    background-color: #fff; /* White background color */
    animation: fadeIn 0.5s ease; /* Fade-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: black; /* Black background color */
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth effect */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.close-button:hover {
    opacity: 0.5; /* Lower opacity on hover */
}

.order-details h2 {
    margin-bottom: 20px;
    color: #333; /* Dark grey color */
    font-size: 24px; /* Larger font size for headings */
    text-transform: uppercase; /* Uppercase headings */
}

.order-details p {
    margin: 10px 0;
    font-size: 16px; /* Adjust font size */
    color: #666; /* Light grey color */
    line-height: 1.5; /* Improved line height for readability */
}

.product-image {
    width: 100px; /* Adjust product image size */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth effect */
    transition: transform 0.3s ease; /* Smooth scale transition */
}

.product-image:hover {
    transform: scale(1.1); /* Scale up image on hover */
}

.track-order-button {
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth effect */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    background-color: #723207; /* Background color */
}

.track-order-button:hover {
    background-color: #2C1907; /* Darker background color on hover */
}

.loading-text {
    font-size: 18px; /* Adjust font size */
    color: #888; /* Light grey color */
}

.tracking-info {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth effect */
}

.tracking-info h3 {
    margin-bottom: 10px;
    font-size: 20px; /* Larger font size for section headings */
    color: #333; /* Dark grey color */
}

.tracking-info p {
    margin: 5px 0;
    font-size: 16px; /* Adjust font size */
    color: #555; /* Dark grey color */
    line-height: 1.5; /* Improved line height for readability */
}

.block3 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Allow wrapping of flex items */
}

.block3 button {
    height: fit-content;
    margin-top: 30px;
    background-color: #723207;
    border-radius: 30px;
    flex: 1 1 45%; /* Adjust button width on smaller screens */
    margin: 30px;

}

.block3 button:hover {
    background-color: #2C1907;
}
.timeline {
    margin-top: 20px;
    border-left: 3px solid #007bff;
    position: relative;
}

.timeline-item {
    padding: 10px 20px;
    position: relative;
    margin-left: 20px;
}

.timeline-item::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #007bff;
    border-radius: 50%;
    position: absolute;
    left: -18px;
    top: 15px;
}

.timeline-content {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
/* Responsive Design */
@media (max-width: 768px) {
    .order-details {
        padding: 10px;
        
    }

    .close-button {
        width: 25px;
        height: 25px;
        font-size: 16px;
    }

    .order-details h2 {
        font-size: 20px;
    }

    .order-details p {
        font-size: 14px;
    }

    .product-image {
        width: 80px;
    }

    .track-order-button {
        font-size: 14px;
        padding: 8px 16px;
    }

    .tracking-info h3 {
        font-size: 18px;
    }

    .tracking-info p {
        font-size: 14px;
    }
}
