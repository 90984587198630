
.policy-container {
    max-width: 800px;
    margin: 0 auto;
    
    padding: 50px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .policy-container h2 {
    color: #723207;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .policy-container h3 {
    color: #DCC0A9;
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .policy-container p {
    color: #333;
    margin-bottom: 15px;
  }
  
  .policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .policy-container a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .policy-container a:hover {
    text-decoration: underline;
  }
