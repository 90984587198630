.reset-password-main {
     display: flex;
     flex-direction: column;
     justify-content: center;
    align-items: center;
    

  }
  
    .reset-password-container {

    width: 400px;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
   
    padding-top: 50px;
  }
  
  .reset-password-container h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .reset-password-container p {
    margin-bottom: 1rem;
    color: #666;
  }
  
  .reset-password-container.form-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .reset-password-container.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .reset-password-container .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .reset-password-container button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-password-container button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-container.error {
    color: red;
    margin-bottom: 1rem;
  }
  
  .reset-password-container.success {
    color: green;
    margin-bottom: 1rem;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
