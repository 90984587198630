/* src/components/PaymentComplete.css */

.payment-complete {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 95px;
}

.payment-complete h1 {
    margin-bottom: 20px;
}

.order-summary {
    margin-bottom: 20px;
}

.order-summary h2 {
    margin-bottom: 10px;
}

.order-summary p {
    margin: 5px 0;
}

.tracking-info {
    margin-top: 20px;
}
