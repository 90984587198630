
 
  
  .withus .heading{
    margin-top: 50px;
    text-align: center;
    color: brown;
    font-size: 40px;
  }
  
  .withus  .main-section{
    width: 95%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    gap: 30px;
    /* padding-left: 50px;*/
  }
  
  .withus .left{
    width: fit-content;
  }
  
  .withus  .left h2{
     font-size: 60px;
    letter-spacing: 2px;
    font-family: serif;
    color: darkslategray;
    font-weight: 100;
    width: 80%;
  }
  
  .withus .left img{
    height: 300px;
    width: 600px;
  }
  
  .withus .right{ width: fit-content;
    margin-top: 40px;
    display: flex;
    /* margin-right: 60px; */
    /* margin-left: 100px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .withus .right p{
    width: 70%;
    line-height: 30px;
    font-weight: 400;
    color: darkslategray;
    font-family: 'poppins', sans-serif;
  }
  
  .withus .subscribe-container{
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #d8b89a; /* Beige background color */
    border-radius: 50px; /* Fully rounded corners */
    overflow: hidden;
    width: 80%;
   padding: 4px;
   
  }
  
  .withus .subscribe-container .email-input {
     border: none;
    padding: 15px;
    font-size: 1rem;
    outline: none;
    width: 100%;
    flex: 1;
    color: white;
   background:transparent;
    border-radius: 50px 0 0 50px; 
   
  }
  .withus  input[type='email']::placeholder {
    color: rgb(239, 239, 239); /* Change this to any color you want */
    opacity: 1; /* To ensure the color is fully applied */
    font-size: 20px;
    font-weight: bolder;
  }
   
  .withus  .subscribe-button {
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    background-color: #663300; /* Brown button background color */
    color: white;
    cursor: pointer;
    outline: none;
  
    border-radius: 50px;
   
  }
  
  .withus  .subscribe-button:hover {
    background-color: #4d2600; /* Darker brown on hover */
  }
  
  .withus  .iconss{
       width: 70%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    
  }
  
   
  
  .withus  .iconss a{
   
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    align-items: center; ;
    border-radius: 50%;
    font-size: 2rem;
    color: brown;
    /* margin-top: 20px; */
    transition: .5s ease;
    
  }
  
  .withus  .iconss a:hover{
  /* background:  white; */
  color: #323946;
  /* box-shadow: 0.4px 0 0.4px 0 #4d2600; */
  }
  .withus .right h2{
    font-size: 60px;
    letter-spacing: 2px;
    font-family: serif;
    color: darkslategray;
    font-weight: 100;
    width: 80%;
    text-align: center;
  }
  @media(max-width : 1024px){
    .withus .main-section {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
       align-items: center;
  }
  .withus .left {
    margin-left: 60px;
    width: 80%;
}
withus .right {
  width: 80%;

  margin-left: 60px;
}
.withus .left img {
  width: 100%;
}
  }
  
  @media(max-width : 768px){
  
    .withus  .main-section{
      width: 100%;
      
       display: flex;
       flex-direction: column;
   }
   .withus .heading{
    width: 100%;
   margin: auto;
   margin-top: 50px;
   margin-left: 0px;
   font-size: 30px;

   }
   .withus  .left{
    width: 100%;
    justify-content: space-around;
  text-align: center;
  align-items: center;
  margin-left: 0;
  
  
    }
  
    .withus   .left h2{
      width: 100%;
      margin-left: 0px;
      font-size: 50px;
      margin-top: 0;
     
    }
  
    .withus   .left img{
        width: 400px;
        height: 200px;
      margin-top: 20px;
      margin-right: 0px;
      margin-left: 0px;
    }
    .withus  .right{
      width: 90%;
      margin: auto;
      /* margin-left: 0; */
      /* padding: 20px;*/

  }
  
  .withus  .right .subscribe-container{
    width: 100%;
    padding: 0;
   }
  
   .withus  .right p{
    margin-left: 0px;
    text-align: center;
  } 
  .withus  .iconss{
    margin-right: 0;
  }
  
  }
  @media(max-width : 420px){
    .withus .right h2{
      font-size: 35px;
    }
  }
