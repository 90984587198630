/* src/components/Slider1.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
    margin: 0;
    padding: 0;
}

.slider-container1 .slick-dots {
    bottom: -30px;
}

.slider-container1 .slick-dots li button:before {
    color: #ffffff; /* Change dot color */
    font-size: 8px;
}

.slider-container1 .slick-dots li.slick-active button:before {
    color: #723207; /* Change active dot color */
    font-size: 15px;
}
.slider-container1 .slick-list {
    max-height: 200px; /* Maximum height you want to allow */
     /* Allows scrolling if content exceeds max-height */
  }
.custom-slick-track{
  height: fit-content;
  padding: 0;
}
.slider-container1 {
    width: 90%;
    margin: 60px auto;
    margin-top: 20px;
    height: fit-content;
    margin-bottom: 0;
    padding-bottom: 0;
    transition: all 0.3s ease-in-out;
}

.slider-container1 .slick-slide:hover{
    transition: all 0.3s ease-in-out;
transform: scale(1.05);
}
.slider-container1 .slick-slide img {
    max-width: 70%; /* Adjust the maximum width of the image */
    height: auto; 
}
.custom-slick-track{
  height: fit-content;
}
.slider-container1 .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0px; */
    margin-right: 5px;
    width: 100px;
    /* margin-left: 0px; */
}
 .slider-container1 .slick-prev,.slick-next{
  font-size: 32px; /* Adjust arrow size */
  color: #000000; /* Arrow color */
  /* position: absolute; */
  top: 23%;
  transform: translateY(-50%);
  z-index: 1; /* Ensure arrows are above the slides */
  background: transparent; /* Remove background */
  cursor: pointer; /* Add cursor pointer for usability */
}
/* .slider-container1 .slick-next{
  margin-right: ;
} */
.slick-prev:before,.slick-next:before {
  font-family: 'slick';
  font-size: 40px;
  line-height: 1;
  opacity: .75;
  color: rgb(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-container1 .slick-prev {
    left: -33px;
    
    margin-bottom: 30%;
}

.slider-container1 .slick-next {
    right: 0px; /* Position right arrow */
}

@media (max-width: 768px) {
    .slider-container1 {
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .slider-container1 .slick-slide img {
      max-width: 70%; /* Adjust the maximum width of the image */
      height: auto; /* Maintain aspect ratio */
  }
  .slider-container1 .slick-slide {
    margin-right: 4px;
   
}
.slider-container1 .slick-list {
    max-height: 150px; /* Maximum height you want to allow */
     /* Allows scrolling if content exceeds max-height */
  }
  
}
@media (max-width: 420px){
    .slider-container1 .slick-list {
        max-height: 100px; /* Maximum height you want to allow */
         /* Allows scrolling if content exceeds max-height */
      }
}
