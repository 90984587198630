.forgot-password-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url('../assets/login_bg.png');
    background-color: #F4E9DC;
   
    
  }
  
  .forgot-password-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 400px;
    
    border-radius: 25px;
    box-shadow:  4px 4px 4px 4px rgba(0, 0, 0, 0.1);
 
   
    backdrop-filter: blur(5px);
    
  }
  
  .forgot-password-container h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .forgot-password-container p {
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
  }
  
  .forgot-password-container .form-group {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .forgot-password-container .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .forgot-password-container .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .forgot-password-container button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .forgot-password-container button:hover {
    background-color: #0056b3;
  }
  
  .forgot-password-container .links {
    margin-top: 1rem;
  }
  
  .forgot-password-container .links a {
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password-container .links a:hover {
    text-decoration: underline;
  }
  
  .forgot-password-container .message {
    color: green;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .forgot-password-container .error {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
  }
  .for_popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%;
    max-width: 400px;
    text-align: center;
  }
  
  .for_popup.success {
    border-color: #4caf50;
    color: #4caf50;
  }
  
  .for_popup.error {
    border-color: #f44336;
    color: #f44336;
  }
  
  .for_popup button {
    background: red;
    padding: 10px;
    color: white;
    border: none;
   
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .for_popup button:hover {
    text-decoration: underline;
  }
  
