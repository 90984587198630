.testimonial{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #F4E9DC;
    align-items: center;
    padding: 0px;
    border-radius: 50px;
    height: fit-content;
    
}
.testimonial img{
    width: 40%;
    border-radius: 100%;
    border: 1px solid rgb(74, 31, 31);

}
.testimonial p{
    width: 90%;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;

}
.testimonial h2{
    margin-top: 10px;
}
.testimonial .rating{
    width: 50%;
    border: 0;
  
}
.testimonial:hover{
    box-shadow: 0 0 1px 0 black;
}
.container2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}
.container2 h2{
    font-size: 40px;
    color: #723207;



}

.container2 hr{
    width: 15%;
    border: 2px solid #723207;
    color: #723207;
    margin-top: 8px;

}
.swiper-container{
   
   margin-left: 30px;  

    
  }
  /* Custom styles for Swiper container */
.mySwiper {
    width: 100%;
    height: 450px;
    position: relative;
  }
  
  /* Custom styles for Swiper slides */
  .mySwiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    /* background-color: #eee;  */
    border-radius: 10px; /* Rounded corners for slides */
    transition: transform 0.3s ease-in-out;
  }
 
  
  .mySwiper .swiper-slide:hover {
    transform: scale(1.05); /* Slightly enlarge the slide on hover */
  }
  
  /* Center the pagination dots */
  .swiper-container .swiper-pagination {
    top: 97%; /* Adjust this value to move dots up or down */
    margin-left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
    width: auto;
  }
  
  .mySwiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color:rgb(105, 52, 52); /* Default color for dots */
    opacity: .4;
    border-radius: 50%; /* Make the dots circular */
    margin: 0 4px; /* Adjust spacing between dots */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .mySwiper .swiper-pagination-bullet-active {
    background-color: rgb(74, 31, 31); /* Color for active dot */
    transform: scale(1.4); /* Slightly enlarge the active dot */
    opacity: 0.8;
  }
  
  .mySwiper .swiper-pagination-bullet:hover {
    background-color: rgb(105, 52, 52); /* Color for hovered dot */
    transform: scale(1.1); /* Slightly enlarge the hovered dot */
    opacity: 0.8;
  }
  

/* .swiper-container .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: rgb(105, 52, 52);
    opacity: 0.8;
    margin: 0 4px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 90%;
 
  
  }

  .swiper-container .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: brown;
    width: 15px;
    height: 15px;
  } */
  .swiper-container h2{
    font-size: 25px;
  }

  
@media(max-width:768px){ 

    .testimonial{
        width: 60%;
        margin-left:-20px;
       
    }

    .swiper-container .swiper-pagination {
      
      margin-left: 45%;
     
    
    }
}
@media(max-width:480px){ 

  .testimonial{
      width: 60%;
      margin-left:-20px;
     
  }

  .swiper-container .swiper-pagination {
    
    margin-left: 45%;
   
  
  }
}
@media(max-width:345px){ 

  .testimonial{
      width: 60%;
      margin-left:-20px;
     
  }
  .testimonial h2{
    font-size: 20px;
  }
  .testimonial p{
    font-size: 12px;
    margin-top: 0;
  }

  .swiper-container .swiper-pagination {
    
    margin-left: 45%;
   
  
  }
}
