.shop_main{
    height: fit-content;
    /* background: url('../assets/login_bg.png'); */
    background-size: cover;
    background-color: #DCC0A9;
    
   
}
.shop-page {
    display: flex;
 
   
}

.sidebar {
    width: 200px;
    border-right: 1px solid #ccc;
    padding: 20px;
    background-color: #F4E9DC;
    border: 1px solid brown;
  
}

.sidebar-item {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px;
}

.sidebar-item:hover {
    color: brown;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
    padding: 20px;
    margin-left: 100px;
}

.product-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}
/* Sidebar.css */


.sidebar-item.active {
   color: brown;
    font-weight: bold;
}
.shop_main {
    position: relative;
}

.categories-button {
    display: none;
    position: fixed;
    /* top: 10px;
    left: 10px; */
    z-index: 1000;
    background: transparent;
    color: black;
    border: none;
    border: 1px solid black;
    padding: 5px 20px;
    margin-top: 20px;
  margin-bottom: 20px;
    cursor: pointer;


}

 .close-button_shop {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
}

.shop-page.sidebar-visible .sidebar {
    transform: translateX(0);
}

/* .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: #f4f4f4;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
} */

.sidebar.visible {
    transform: translateX(0);
}
.error-container{
    width: 100%;
}
@media (max-width: 768px){
    .product-list{
        margin-left: 0;
    }
    .shop_main .card1{
        margin-left: 0;
  }
  .shop_main .footer{
    background-color:#DCC0A9 ;
  }
  .categories-button {
    display: block;
    left: 36%;
    z-index: 1;
    position: absolute;
}
.shop-page{
    padding-top: 36px;
}
.close-button_shop {
    display: block;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background: #fff;
    z-index: 1000;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    border-right: 1px solid #ccc;
    padding: 20px;
    background-color: #F4E9DC;
    border: 1px solid brown;
  
    
}

.sidebar.visible {
    transform: translateX(0);
    padding-top: 50px;
}

.product-list {
    margin-left: 0;
}


}
/* ShopPage.css */





