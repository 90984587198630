.company_main{
    width: 100%;
    display: block;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
   padding: 50px 0px 50px 0px;
   height: fit-content;
   background-color: #F4E9DC;
}
.company_logo{
    width: 15%;
    height: fit-content;
   
}
.company_main h2{
    font-size: 30px;
}
.company_sub{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
}
.company_logo img{
    content: attr(title);
    height: 150px; /* Set fixed height */
    width: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image doesn't overflow the container */
    background-color: transparent; /* Remove background color */
    object-fit: contain; /* Ensure image fits within the bounds */
cursor: pointer;
}
@media (max-width: 768px){
    .company_logo{
        width: 40%;
       
    }
    .company_logo img{
        width: 100%;
    }
}