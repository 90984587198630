/* src/components/FakePaymentGateway.css */

.fake-payment-gateway {
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 95px;
}

.fake-payment-gateway h1 {
    text-align: center;
    margin-bottom: 20px;
}

.fake-payment-gateway .form-group {
    margin-bottom: 15px;
}

.fake-payment-gateway .form-group label {
    display: block;
    margin-bottom: 5px;
}

.fake-payment-gateway .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fake-payment-gateway button {
    width: 100%;
    padding: 10px;
    background-color: #A17659;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.fake-payment-gateway button:hover {
    background-color: #402614;
}
